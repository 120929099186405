import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';

import SwapService from "../../services/swap.service";
import CollectibleService from "../../services/collectible.service";
import UserService from "../../services/user.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    },
    paperText: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderStyle: 'solid',
      borderColor: '#FFF',
      borderWidth: 1,
      marginTop: 5,
      marginLeft: 5,
      marginRight: 10
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 552,
      width: 286
    },
  }),
);

const MatchList = (props) => {
  const classes = useStyles();
  const matchs = props.collectibles;
  
  const confirmSwap = async (idSwap, idMatch) => {
    await SwapService.confirmSwap(idMatch, idSwap);
  }

  const listItems = matchs.map((match) =>
    <React.Fragment className={classes.cardBorder} key={match._id}>
      <Grid item xs={4}>
        <Card className={classes.rootCard}>
          <CardActionArea>
            <div>
              <CardMedia
                className={classes.media}
                image={match.url}
                title={match.name}
              />
            </div>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => { confirmSwap(match.idSwap, match._id) }}>
              Swap
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const SwapDetail = () => {
  const classes = useStyles();
  const [swap, setSwap] = useState({});
  const [matchs, setMatchs] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {  
    try {
      const s = await getSwap(id);
      setSwap(s);
    } catch (e) {}

    try {
      const m = await getMatchs();
      setMatchs(m);
    } catch(e) {}
  }

  const getSwap = async (id) => {
    try {
      const swap = await SwapService.getSwap(id);

      if(swap.length > 0) {
        const tokenUri = await CollectibleService.getTokenURI(swap.item);
        const meta = await axios.get(tokenUri);
        let item = {
          idSwap: swap.id.toNumber(),
          _id: swap.item.toNumber(),
          name: meta.data.name,
          generation: meta.data.attributes[5].value,
          level: meta.data.attributes[2].value,
          status: meta.data.attributes[3].value,
          swapStatus: 2,
          element: meta.data.attributes[1].value,
          url: meta.data.image,
          code: swap.code,
          owner: swap.owner,
        };
        console.log('item', item);
        return Promise.resolve(item);
      } else {
        return Promise.reject('error');
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const getMatchs = async () => {
    try {
      const data = await SwapService.getMatchOfSwap(id);
      console.log('data', data);
      try {
        const items = await Promise.all(data.map(async (i) => {
          if(i.swapId.toNumber() != 0) {
            const tokenUri = await CollectibleService.getTokenURI(i.item);
            const meta = await axios.get(tokenUri);
            let item = {
              idMatch: i.id.toNumber(),
              idSwap: i.swapId.toNumber(),
              _id: i.item.toNumber(),
              name: meta.data.name,
              generation: meta.data.attributes[5].value,
              level: meta.data.attributes[2].value,
              status: meta.data.attributes[3].value,
              element: meta.data.attributes[1].value,
              url: meta.data.image,
              owner: i.owner,
            };
            return item;
          }
        }));
        var filtered = items.filter(function(x) {
          return x !== undefined;
        });
        console.log('items', items);
        return Promise.resolve(filtered);
      } catch(e) {
        console.log('e', e);
        return Promise.reject(e);
      }
    } catch (e) {
      console.log('e', e);
      return Promise.reject(e);
    }
  }

  return (
    <React.Fragment>
      <h2>Swap #{swap.code}</h2>
      <Grid container spacing={3}>
        {swap && swap && (
          <Grid item xs={6} key={swap.idSwap}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={swap.url}
                    title={swap.name}
                  />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
      {matchs && matchs.length > 0 && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <MatchList collectibles={matchs} getMatchs={getMatchs} />
          </Grid>
        </div>
      )}
    </React.Fragment>
  )
}

export default SwapDetail;
