import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import SwapService from "../../services/swap.service";
import CollectibleService from "../../services/collectible.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    cardBorder: {
      borderColor: 'green',
      borderWidth: 1,
      borderStyle: 'solid'
    },
    rootCard: {
      maxWidth: 286,
    },
    rootCard2: {
      maxWidth: 230,
      marginLeft: -55,
    },
    media: {
      height: 552,
      width: 286
    },
  }),
);

const MySwapsList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const swaps = props.collectibles;

  const openSwap = async (id) => {
    history.push("/swapdetail/" + id);
  }

  const cancel = async (id) => {
    try {
      await SwapService.cancelSwap(id);
      props.getSwaps();
    } catch(e) {}
  }

  const listItems = swaps.map((swap) =>
    <Grid item xs={3} key={swap._id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <div onClick={() => { openSwap(swap.idSwap) }}>
            <CardMedia
              className={classes.media}
              image={swap.url}
              title={swap.name}
            />
            <CardContent>
              <Typography gutterBottom variant="body2" component="p">
                {swap.code}
              </Typography>
              {/* <Typography gutterBottom variant="h5" component="h2">
                {swap.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Element: {swap.element}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Level: {swap.level}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Price: {swap.tokenOne.price} FTM
              </Typography> */}
            </CardContent>
          </div>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => { cancel(swap.idSwap) }}>
            Cancel swap
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const MyMatchList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const matchs = props.collectibles;

  const openMatch = async (id) => {
    history.push("/match/" + id);
  }

  const cancel = async (id) => {
    try {
      await SwapService.cancelMatch(id);
      props.getMatchs();
    } catch(e) {}
  }

  const listItems = matchs.map((match) =>
    <React.Fragment className={classes.cardBorder} key={match._id}>
      <Grid item xs={6}>
        <Card className={classes.rootCard}>
          <CardActionArea>
            <div onClick={() => { openMatch(match.idMatch) }}>
              <CardMedia
                className={classes.media}
                image={match.url}
                title={match.name}
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {match.seller.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Element: {match.seller.element}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Level: {match.seller.level}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Price: {match.tokenOne.price} FTM
                </Typography>
              </CardContent> */}
            </div>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => { cancel(match.idMatch) }}>
              Cancel match
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const Swaps = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [openSwaps, setOpenSwaps] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [matchs, setMatchs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    await getSwaps();
    await getMatchs();
  }

  const getSwaps = async () => {
    try {
      const data = await SwapService.getSwaps();
      try {
        const items = await Promise.all(data.map(async (i) => {
          const tokenUri = await CollectibleService.getTokenURI(i.item);
          const meta = await axios.get(tokenUri);
          let name = meta.data.name
          if(name.indexOf("-") > -1) {
            name = name.split("-")[0];
          }

          let item = {
            idSwap: i.id.toNumber(),
            _id: i.item.toNumber(),
            name: name,
            generation: meta.data.attributes[5].value,
            level: meta.data.attributes[2].value,
            status: meta.data.attributes[3].value,
            swapStatus: 2,
            element: meta.data.attributes[1].value,
            url: meta.data.image,
            code: i.code,
            owner: i.owner,
          };
          return item;
        }));
        setSwaps(items);
      } catch(e) {}
    } catch (e) {}
  }

  const getMatchs = async () => {
    try {
      const data = await SwapService.getMatchs();
      try {
        const items = await Promise.all(data.map(async (i) => {
          const tokenUri = await CollectibleService.getTokenURI(i.item);
          const meta = await axios.get(tokenUri);
          let name = meta.data.name
          if(name.indexOf("-") > -1) {
            name = name.split("-")[0];
          }

          let item = {
            idMatch: i.id.toNumber(),
            idSwap: i.swapId.toNumber(),
            code: i.code,
            _id: i.item.toNumber(),
            name: name,
            generation: meta.data.attributes[5].value,
            level: meta.data.attributes[2].value,
            status: meta.data.attributes[3].value,
            swapStatus: 2,
            element: meta.data.attributes[1].value,
            url: meta.data.image,
            owner: i.owner,
          };

          return item;
        }));
        console.log('items', items);
        setMatchs(items);
      } catch(e) {}
    } catch (e) {}
  }

  const searchSwap = async () => {
    const data = swaps.map( (obj) => {
      return obj.code === search;
    });

    if(data.length > 0) {
      alert("You cannot open your own swap");      
    } else {
      history.push("/swap/" + search);
    }
  }

  return (
    <React.Fragment>
      <h2>Search swap</h2>
      <input type="text" onChange={e => setSearch(e.target.value)} placeholder="Swap Code" />
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ searchSwap }>Search</Button>
      {/* <h2>Open swaps</h2> */}
      {openSwaps && openSwaps.length > 0 && (
        {/* <div className={classes.root}>
          <Grid container spacing={3}>
            <SwapsList collectibles={openSwaps} getOpenSwaps={getOpenSwaps} />
          </Grid>
        </div> */}
      )}
      <h2>My swaps</h2>
      {swaps && swaps.length > 0 && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <MySwapsList collectibles={swaps} getSwaps={getSwaps} />
          </Grid>
        </div>
      )}
      <h2>My matchs</h2>
      {matchs && matchs.length > 0 && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <MyMatchList collectibles={matchs} getMatchs={getMatchs} />
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

export default Swaps;
