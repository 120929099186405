import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ethers } from "ethers";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

import "./MyNFTs.css";
import CollectibleService from "../../services/collectible.service";
import SwapService from "../../services/swap.service";
import MarketplaceService from "../../services/marketplace.service";
import EscrowService from "../../services/escrow.service";
import TrainersService from "../../services/trainers.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 180,
    },
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    backgroundColor: '#FFF'
  };
}

const TokensList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [modalToken, setModalToken] = useState({});
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [modalStyle] = useState(getModalStyle);
  const tokens = props.collectibles;

  const openToken = async (id, token) => {
    localStorage.setItem("nft", JSON.stringify(token));
    history.push("/mynft/" + id);
  }

  const evolve = async (id) => {
    history.push("/evolve/" + id);
  }
  
  const approve = async (id) => {
    try {
      const approve = await CollectibleService.approve(id);
    } catch(e) {}
  }

  const deposit = async (id) => {
    try {
      await EscrowService.deposit(id);
    } catch(e) {}
  }

  const withdraw = async (id) => {
    try {
      await EscrowService.withdraw(id);
    } catch(e) {}
  }

  const publishSwap = async (id) => {
    try {
      await SwapService.publishSwap(id);
    } catch(e) {}
  }

  const publishSale = async (id) => {
    if(amount > 0){
      try {
        await MarketplaceService.publishSale(id, amount);
        history.push("/marketplace");
      } catch(e) {}
    } else {
      alert("Must give a price");
    }
  }

  const mintTrainer = async (id) => {
    try {
      await TrainersService.mint(id);
    } catch(e) {}
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listItem = (
    <Grid item xs={12} key={modalToken.id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <CardMedia
            className={classes.mediaList}
            image={modalToken.url}
            title={modalToken.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {modalToken.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Give a price for sell</h2>
      <Grid container spacing={12} direction="column" alignItems="center" justify="center">
        {listItem}
      </Grid>
      <input type="number" onChange={e => setAmount(e.target.value)} placeholder="Price" />
      <br />
      <Button size="small" color="primary" onClick={() => {publishSale(modalToken._id)}}>
        Publish sale
      </Button>
    </div>
  );
  
  const listItems = tokens.map((token) =>
    <React.Fragment key={token._id}>
      <Grid item xs={3}>
        <Card className={classes.rootCard}>
          <CardActionArea>
            <div onClick={() => {openToken( ethers.utils.formatUnits(token._id, 0), token)}} >
              <CardMedia
                className={classes.media}
                image={token.url || ""}
                title={token.name}
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {token.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Element: {token.element}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Level: {token.level}
                </Typography>
                { token.price && (
                  <Typography variant="body2" color="textSecondary" component="p">
                    Price: {ethers.utils.formatUnits(token.price._hex)} FTM
                  </Typography>
                )}
              </CardContent> */}
            </div>
          </CardActionArea>
          { token.swapStatus === 1 && (
            <CardActions>
              { (token.status === 0 || token.status === 1) && (token.level < 3) && (
                <Button size="small" color="primary" onClick={() => {evolve(token._id)}}>
                  Evolve
                </Button>
              )}
              { token.status === 0 && (
                <React.Fragment>
                  <Button size="small" color="primary" onClick={() => {approve(token._id)}}>
                    Approve
                  </Button>
                </React.Fragment>
              )}
              { token.status === 1 && (
                <React.Fragment>
                  <Button size="small" color="primary" onClick={() => {publishSwap(token._id)}}>
                    Publish Swap
                  </Button>
                </React.Fragment>
              )}
              { token.level === 3 && (
                <Button size="small" color="primary" onClick={() => {mintTrainer(token._id)}}>
                  Generate Trainer
                </Button>
              )}
            </CardActions>
          )}
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const MyNFTs = () => {
  const classes = useStyles();
  const [collectibles, setCollectibles] = useState([]);
  const [elements, setElements] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect( () => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    /* await mintEvent(); */
    await getTokens();
  }

  const getTokens = async () => {
    try {
      const data = await CollectibleService.getCollectiblesOfOwner();
      try {
        const items = await Promise.all(data.map(async (i) => {
          console.log('status', i.status);
          /* if(i.id.toNumber() !== 0) { */
            try {
              const level = (i.level === 3) ? i.level - 1 : i.level;
              /* let tokenUri = await CollectibleService.getBurnedURI(i.id, level); */
              let tokenUri = await CollectibleService.getTokenURI(i.id);
              let meta = await axios(tokenUri);
              meta = meta.data;
              let name = meta.name
              if(name.indexOf("-") > -1) {
                name = name.split("-")[0];
              }
              let item = {
                _id: i.id.toNumber(),
                name: name,
                generation: i.generation,
                level: i.level,
                status: i.status,
                swapStatus: i.swapStatus,
                element: meta.attributes[2].value,
                url: meta.image,
                owner: i.owner,
              };
              return item;
            } catch(e) {
              console.log('e', e);
            }
          /* } */
        }));
        setCollectibles(items);
      } catch(e) {}
    } catch(e) { console.log('e', e); }
  }

  const mint = async () => {
    try {
      if(typeof(parseInt(quantity)) == "number") {
        if(parseInt(quantity) > 0) {
          await CollectibleService.mint(parseInt(quantity));
        } else {
          setErrorMsg("Quantity must be greater than 0");
          setError(true);

          setTimeout(() => {
            setErrorMsg("");
            setError(false);
          }, 6000);
        }
      } else {
        setErrorMsg("Quantity is not a number");
        setError(true);

        setTimeout(() => {
          setErrorMsg("");
          setError(false);
        }, 6000);
      }
    } catch(e) {
      setErrorMsg(e.message);
      setError(true);

      setTimeout(() => {
        setErrorMsg("");
        setError(false);
      }, 6000);
    }
  }

  return (
    <React.Fragment>
      <h2>My Fantom Monsters</h2>
      { collectibles && collectibles.length > 0 && (
         <div className={classes.root}>
           <Grid container spacing={3}>
              <TokensList collectibles={collectibles} />
           </Grid>
         </div>
      )}
      { collectibles && collectibles.length < 1 && (
        <React.Fragment>
          <h4>You don't have tokens</h4>
        </React.Fragment>
      )}
      <div style={{ marginTop: 20 }}>
        <span>How much do you want to mint?</span>
        <input type="number" placeholder="1" name="quantity" value={quantity} onChange={ (e) => setQuantity(e.target.value)} />
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ mint }>Mint</Button>
      </div>
      { error && (
        <div className={classes.rootAlert}>
          <Alert severity="error">{ errorMsg }</Alert>
        </div>
      )}
    </React.Fragment>
  );
}

export default MyNFTs;
