import { ethers } from "ethers";
import Fmonsters from "../artifacts/contracts/Fmonsters.sol/Fmonsters.json";

const config = require('../config.json');
const contract_address = config.development.contract_address || "";

const pauseFunction = async (type) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    const pause = await contract.setPauseFunction(type);
    return Promise.resolve(pause);
  } catch (e) {
    return Promise.reject(e);
  }
}

const setMaxSupply = async (number) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    const set = await contract.setMaxSupply(number);
    return Promise.resolve(set);
  } catch (e) {
    return Promise.reject(e);
  }
}

const setGeneration = async (number) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    const set = await contract.setGeneration(number);
    return Promise.resolve(set);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getMaxSupply = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    const supply = await contract.getMaxSupply();
    const total = ethers.utils.formatUnits(supply, 0);
    return Promise.resolve(total);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getGeneration = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    let generation = await contract.getGeneration();
    generation = ethers.utils.formatUnits(generation, 0);
    return Promise.resolve(generation);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getGenerationSupply = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    let total = await contract.getGenerationSupply();
    total = ethers.utils.formatUnits(total, 0);
    return Promise.resolve(total);
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  pauseFunction,
  setMaxSupply,
  setGeneration,
  getMaxSupply,
  getGeneration,
  getGenerationSupply
}