import { ethers } from "ethers";

const FmonstersTrainers = require("../artifacts/contracts/FmonstersTrainers.sol/FmonstersTrainers.json");
const config = require("../config.json");
const contract_trainers_address = config.development.contract_trainers_address || "";
console.log('contract_trainers_address', contract_trainers_address);
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const trainers = new ethers.Contract(contract_trainers_address, FmonstersTrainers.abi, signer);
console.log('trainers', trainers);

const mint = async (fmonsterId) => {
  try {
    const mint = await trainers.mint(fmonsterId);
    return Promise.resolve(mint);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getCollectiblesOfOwner = async () => {
  try {
    const collectibles = await trainers.getTrainersOfOwner();
    console.log('trainers', collectibles);
    return Promise.resolve(collectibles);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getTokenURI = async (id) => {
  try {
    const uri = await trainers.tokenURI(id);
    return Promise.resolve(uri);
  } catch(e) {
    return Promise.reject(e);
  }
}

export default {
  mint,
  getCollectiblesOfOwner,
  getTokenURI
}
