import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import MarkeplaceService from "../../services/marketplace.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 280,
    },
  }),
);

const SalesList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const sales = props.sales;

  const openSale = async (id) => {
    history.push("/sale/" + id);
  }

  const buy = async (id, idToken, price) => {
    try {
      await MarkeplaceService.confirmSale(id, idToken, price);
      props.getSales();
      props.getMySales();
    } catch(e) {

    }
  }

  const listItems = sales.map((sale) =>
    <Grid item xs={3} key={sale.token._id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <div onClick={() => { openSale(sale._id) }}>
            <CardMedia
              className={classes.media}
              image={sale.token.url}
              title={sale.token.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {sale.token.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Element: {sale.token.element}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Level: {sale.token.level}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Price: {sale.token.price} FTM
              </Typography><Typography variant="body2" color="textSecondary" component="p">
                Sale Price: {sale.price} FTM
              </Typography>
            </CardContent>
          </div>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => { buy(sale._id, sale.token._id, sale.price) }}>
            Buy
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const MySalesList = (props) => {
  const classes = useStyles();
  const sales = props.sales;

  const cancel = async (id, idToken) => {
    try {
      await MarkeplaceService.cancelSale(id, idToken);
      props.getMyOpenSwaps();
    } catch(e) {

    }
  }

  const listItems = sales.map((sale) =>
    <Grid item xs={3} key={sale.token._id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <div>
            <CardMedia
              className={classes.media}
              image={sale.token.url}
              title={sale.token.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {sale.token.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Element: {sale.token.element}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Level: {sale.token.level}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Price: {sale.token.price} FTM
              </Typography>
            </CardContent>
          </div>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => { cancel(sale._id, sale.token._id) }}>
            Cancel sale
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const Marketplace = () => {
  const classes = useStyles();
  const [sales, setSales] = useState([]);
  const [mySales, setMySales] = useState([]);


  useEffect( () => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    await getSales();
    await getMySales();
  }

  const getSales = async () => {
    try {
      const data = await MarkeplaceService.getSales();
      setSales(data);
    } catch (e) {

    }
  }

  const getMySales = async () => {
    try {
      const data = await MarkeplaceService.getMySales();
      setMySales(data);
    } catch (e) {

    }
  }

  return (
    <React.Fragment>
      <h2>Sales</h2>
      {sales && sales.length > 0 && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <SalesList sales={sales} getSales={getSales} getMySales={getMySales} />
          </Grid>
        </div>
      )}
      <h2>My sales</h2>
      {mySales && mySales.length > 0 && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <MySalesList sales={mySales} getMySales={getMySales} />
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

export default Marketplace;
