import axios from "axios";

import config from '../config.json';
const api = config.development.api_url || "";

const getUserTokens = async () => {
  const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
  let token = localStorage.getItem('token');
  token = 'bearer ' + token.split('"').join('');
  
  const config = {
    headers: {
      authorization: token,
    }
  };
  
  try {
    const response = await axios.get(`${api}/api/v1/mycollectibles/` + account, config);
    return Promise.resolve(response.data.collectibles);
  } catch (e) {
    return Promise.reject(e);
  }
};

const getUserTokensForMatch = async () => {
  const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
  let token = localStorage.getItem('token');
  token = 'bearer ' + token.split('"').join('');
  
  const config = {
    headers: {
      authorization: token, 
    }
  };
  
  try {
    const response = await axios.get(`${api}/api/v1/mycollectibles/formatch/` + account, config);
    return Promise.resolve(response.data.collectibles);
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  getUserTokens,
  getUserTokensForMatch
}
