import React, { useState } from "react";
import { Button } from "@material-ui/core";

import AdminService from "../../services/admin.service";

const Admin = () => {
  const [supplyMax, setSupplyMax] = useState(0);
  const [gen, setGen] = useState(0);
  const [genSupply, setGenSupply] = useState(0);

  const pause = async (type) => {
    try {
      const data = await AdminService.pauseFunction(type);
    } catch(e) {

    }
  }

  const setMaxSupply = async (number) => {
    try {
      const data = await AdminService.setMaxSupply(number);
    } catch(e) {

    }
  }

  const setGeneration = async (number) => {
    try {
      const data = await AdminService.setGeneration(number);
    } catch(e) {

    }
  }

  const getMaxSupply = async (number) => {
    try {
      const data = await AdminService.getMaxSupply();
      setSupplyMax(data);
    } catch(e) {

    }
  }

  const getGeneration = async (number) => {
    try {
      const data = await AdminService.getGeneration();
      setGen(data);
    } catch(e) {

    }
  }

  const getGenerationSupply = async (number) => {
    try {
      const data = await AdminService.getGenerationSupply();
      setGenSupply(data);
    } catch(e) {
      
    }
  }

  return (
    <React.Fragment>
      <h2>Admin</h2>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => pause("mint") }>Pause Mint</Button>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => setMaxSupply(2) }>Set Max Supply</Button>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => setGeneration(2) }>Set Generation</Button>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => getMaxSupply() }>Get Max Supply { supplyMax }</Button>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => getGeneration() }>Get Generation { gen }</Button>
      <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ () => getGenerationSupply() }>Get Generation Supply { genSupply }</Button>
    </React.Fragment>
  );
}

export default Admin;
