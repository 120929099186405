import { ethers } from "ethers";

const FmonstersEscrow = require("../artifacts/contracts/FmonstersEscrow.sol/FmonstersEscrow.json");
const config = require('../config.json');
const contract_escrow_address = config.development.contract_escrow_address || "";
const api = config.development.api_url || "";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const contract = new ethers.Contract(contract_escrow_address, FmonstersEscrow.abi, signer);

const deposit = async (id) => {
  const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });

  try {
    const deposit = await contract.deposit(account, id, 3);
    return Promise.resolve(deposit);
  } catch (e) {
    return Promise.reject(e);
  }
}

const withdraw = async (id) => {
  try {
    const deposit = await contract.withdraw(id);
    return Promise.resolve(deposit);
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  deposit,
  withdraw
}
