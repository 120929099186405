import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import '@fontsource/roboto';
import { Provider } from 'react-redux';

import store from './store';
import "./App.css";

import Navbar from "./components/navbar/navbar";
import Home from "./pages/home/Home";
import Admin from "./pages/admin/Admin";
import MyNFTs from "./pages/mynfts/MyNFTs";
import MyNFT from "./pages/mynft/MyNFT";
import Evolve from "./pages/evolve/Evolve";
import Swaps from "./pages/swaps/Swaps";
import Swap from "./pages/swap/Swap";
import SwapDetail from "./pages/swapdetail/SwapDetail";
import Match from "./pages/match/Match";
import Marketplace from "./pages/marketplace/Marketplace";
import Trainers from "./pages/trainers/Trainers";
import Graveyard from "./pages/graveyard/Graveyard";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

function App() {
  return(
    <React.Fragment>
      <Router>
        <div>
          <Navbar />
          <Switch>
            <Route path="/mynfts"  component={MyNFTs}></Route>
            <Route path="/mynft/:id" component={MyNFT}></Route>
            <Route path="/evolve/:id" component={Evolve}></Route>
            <Route path="/swaps" component={Swaps}></Route>
            <Route path="/swap/:id" component={Swap}></Route>
            <Route path="/swapdetail/:id" component={SwapDetail}></Route>
            <Route path="/match/:id" component={Match}></Route>
            <Route path="/marketplace" component={Marketplace}></Route>
            <Route path="/trainers" component={Trainers}></Route>
            <Route path="/graveyard" component={Graveyard}></Route>
            <Route path="/admin" component={Admin}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </div>
      </Router>
    </React.Fragment>
  )
}

export default AppWrapper;
