import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

let user, token, typeExtension;

if(localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
}

if(localStorage.getItem("token")) {
  token = localStorage.getItem("token");
}

if(localStorage.getItem("typeExtension")) {
  typeExtension = localStorage.getItem("typeExtension");
}

const initialState = user
  ? { isLoggedIn: true, user, token, typeExtension }
  : { isLoggedIn: false, user: null, token: null, typeExtension: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
        typeExtenstion: payload.typeExtension
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
        typeExtension: null
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        token: null,
        typeExtension: null
      };
    default:
      return state;
  }
}
