import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import "./MyNFT.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    },
    paperText: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderStyle: 'solid',
      borderColor: '#FFF',
      borderWidth: 1,
      marginTop: 5,
      marginLeft: 5,
      marginRight: 10
    },
  }),
);

const RenderObject = (props) => {
  const classes = useStyles();
  const collectible = props.collectible;

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <img src={collectible.url} alt={collectible._id} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paperText}>
            Generation: {collectible.generation}
          </Paper>
          <Paper className={classes.paperText}>
            Element: {collectible.element}
          </Paper>
          <Paper className={classes.paperText}>
            Level: {collectible.level}
          </Paper>
          <Paper className={classes.paperText}>
            Price: {collectible.price}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Button color="primary" variant="contained">Evolve</Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Button color="primary" variant="contained">Publish for Swap</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const MyNFT = (props) => {
  const [collectible, setCollectible] = useState('');

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    setCollectible(JSON.parse(localStorage.getItem("nft")));
  }

  return (
    <React.Fragment>
      <h2>{collectible.name }</h2>
      <RenderObject collectible={collectible} />
    </React.Fragment>
  )
}

export default MyNFT;
