import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Box, Typography, Button } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ethers } from "ethers";

import store from '../../store';
import "./navbar.css";
import { login, logout } from "../../actions/auth";
import config from '../../config.json';
import AuthService from "../../services/auth.service";

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [networkPrompt, setNetworkPrompt] = useState(false);
  const { ethereum } = window;

  useEffect( () => {
    const state = store.getState();
    console.log('state', state);
    const u = state.auth.user;
    
    if(u) {
      setUser(u);
    }
    
    accountListener(u);
    nertworkListener();
  }, []);

  const nertworkListener = async () => {
    if (!networkPrompt) {
      if (ethereum && ethereum.networkVersion && ethereum.networkVersion !== config.development.chainId.toString()) {
        try {
          await dispatch(logout());
          setUser("");
          connectToNetwork(ethereum);
          setNetworkPrompt(true);
        } catch(e) {}
      }
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    provider.on("network", async(newNetwork, oldNetwork) => {
      if(newNetwork.chainId.toString() !== config.development.chainId.toString()) {
        try {
          await dispatch(logout());
          setUser("");
        } catch(e) {

        }
      }
    });
  };

  const accountListener = async (u) => {
    window.ethereum.on('accountsChanged', async (accounts) => {
      if(accounts[0] !== u) {
        try {
          await dispatch(logout());
          setUser("");
        } catch(e) {}
      }
    });
  }

  const connectToNetwork = async (provider) => {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${config.development.chainId.toString(16)}`,
            chainName: config.development.networkName,
            nativeCurrency: {
              name: 'FTM',
              symbol: 'ftm',
              decimals: 18,
            },
            rpcUrls: [config.development.defaultProvider],
            blockExplorerUrls: [config.development.ftmscanUrl],
          },
        ],
      });
    } catch(e) {}
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = async(e = null) => {
    if(e) {
      e.preventDefault();
    }
    try {
      const signin = await dispatch(login());
      try {
        const state = store.getState();
        const u = state.auth.user;
        setUser(u);
      } catch(e) {
        console.log('e', e);
      }
    } catch(e) {
      console.log('e', e);
    }
  }

  const handleLogout = async(e) => {
    e.preventDefault();

    try {
      await AuthService.logout();
      handleClose();
      setUser("");
      history.push("/home");
    } catch(e) {}
  }

  return (
    <Box display="flex" bgcolor="#282c34" p={2} alignItems="center">
      <Typography style={{ color: '#FFF', marginRight: 20 }}>NFTs</Typography>
      <Box flexGrow={1} textAlign="right">
        <Button color="primary"><Link to="/">Home</Link></Button>
        { user && (
          <React.Fragment>
            <Button color="primary"><Link to="/mynfts">My Fmonsters</Link></Button>
            <Button color="primary"><Link to="/trainers">My Trainers</Link></Button>
            <Button color="primary"><Link to="/swaps">Swaps</Link></Button>
          </React.Fragment>
        )}
        <Button color="primary"><Link to="/graveyard">Graveyard</Link></Button>
        { user && (
          <React.Fragment>
            <Button color="primary"><Link to="/admin">Admin</Link></Button>
            <Button aria-controls="simple-menu" aria-haspopup="true" style={{ color: '#FFF' }} onClick={handleClick}>
              { user}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        )}
        { !user && (
          <Button color="primary" style={{ color: '#FFF' }} onClick={handleLogin}>Login</Button>
        )}
      </Box>
    </Box>
  );
}

export default Navbar;
