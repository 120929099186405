import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import SwapService from "../../services/swap.service";
import CollectibleService from "../../services/collectible.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    },
    paperText: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderStyle: 'solid',
      borderColor: '#FFF',
      borderWidth: 1,
      marginTop: 5,
      marginLeft: 5,
      marginRight: 10
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 552,
      width: 286
    },
  }),
);

const Match = () => {
  const classes = useStyles();
  const [match, setMatch] = useState({});
  const [swap, setSwap] = useState({});
  const { id } = useParams();

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    try {
      const m = await getMatch(id);
      console.log('m', m);
      setMatch(m);
      const s = await getSwap(m.code);
      console.log('s', s);
      setSwap(s);
    } catch (e) {

    }
  }

  const getMatch = async(id) => {
    try {
      const match = await SwapService.getMatch(id);
      const tokenUri = await CollectibleService.getTokenURI(match.item);
      const meta = await axios.get(tokenUri);

      let item = {
        idMatch: match.id.toNumber(),
        idSwap: match.swapId.toNumber(),
        code: match.code,
        _id: match.item.toNumber(),
        name: meta.data.name,
        generation: meta.data.attributes[5].value,
        level: meta.data.attributes[2].value,
        status: meta.data.attributes[3].value,
        element: meta.data.attributes[1].value,
        url: meta.data.image,
        owner: match.owner,
      };
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const getSwap = async (code) => {
    try {
      const swap = await SwapService.getSwapByCode(code);
      const tokenUri = await CollectibleService.getTokenURI(swap.item);
      const meta = await axios.get(tokenUri);

      let item = {
        idSwap: swap.id.toNumber(),
        code: swap.code,
        _id: swap.item.toNumber(),
        name: meta.data.name,
        generation: meta.data.attributes[5].value,
        level: meta.data.attributes[2].value,
        status: meta.data.attributes[3].value,
        element: meta.data.attributes[1].value,
        url: meta.data.image,
        owner: swap.owner,
      };
      return Promise.resolve(item);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const cancelMatch = async () => {
    try {
      await SwapService.cancelMatch(id);
    } catch(e) {}
  }

  return (
    <React.Fragment>
      <h2>Match #{match._id}</h2>
      <Grid container spacing={3}>
        {match && match.url && (
          <Grid item xs={6} key={match._id}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={match.url}
                    title={match.name}
                  />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
        {swap && swap.url && (
          <Grid item xs={6} key={swap._id}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={swap.url}
                    title={swap.name}
                  />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
      <Button variant="contained" color="primary" onClick={() => { cancelMatch(match._id) }}>Cancel Match</Button>
    </React.Fragment>
  );
}

export default Match;
