import { ethers } from "ethers";
import axios from 'axios';
import store from '../store';
import WalletLink from "walletlink";
const querystring = require('querystring');
const state = store.getState();

const Fmonsters = require("../artifacts/contracts/Fmonsters.sol/Fmonsters.json");
const FmonstersEvolution = require("../artifacts/contracts/FmonstersEvolution.sol/FmonstersEvolution.json");
const config = require('../config.json');
let contract_mint_address = config.development.contract_mint_address || "";
let contract_evolution_address = config.development.contract_evolution_address || "";
let generation = config.development.generation || "";
const api = config.development.api_url || "";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const contract = new ethers.Contract(contract_mint_address, Fmonsters.abi, signer);
const evolution = new ethers.Contract(contract_evolution_address, FmonstersEvolution.abi, signer);
console.log('contract', contract);

const mint = async (quantity) => {
  const price = (0.05 * quantity).toString();
  let overrides = {
    value: ethers.utils.parseEther(price)
  };
  
  try {
    const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
    const mint = await contract.mint(account, quantity, overrides);
    return Promise.resolve(mint);
  } catch(e) {
    console.log('e', e);
    return Promise.reject(e);
  }
}

const gift = async (account) => {
  const price = (0.05).toString();
  let overrides = {
    value: ethers.utils.parseEther(price)
  };

  try {
    const gift = await contract.mint(account, 1, overrides);
    return Promise.resolve(gift);
  } catch(e) {
    return Promise.reject(e);
  }
}

const evolve = async (type, idEvo, idBurnOne, idBurnTwo = null) => {
  try {
    let token = localStorage.getItem('token');
    token = 'bearer ' + token.split('"').join('');

    const config = {
      headers: {
        authorization: token,
      }
    };

    console.log('type', type);

    if(type === 1) {
      const data = await axios.post(`${api}/api/fmonsters/evolve`, querystring.stringify({ type, idEvo, idBurnOne }), config);
      console.log('data', data);
      const voucher = data.data.data;
      console.log('data', voucher);
      const evolve = await evolution.firstEvolution(voucher);
      return Promise.resolve(evolve);
    } else if(type === 2) {
      const data = await axios.post(`${api}/api/fmonsters/evolve`, { type, idEvo, idBurnOne, idBurnTwo }, config);
      const voucher = data.data.data;
      const evolve = await evolution.secondEvolutionWithTwo(voucher);
      return Promise.resolve(evolve);
    } else if(type === 3) {
      const data = await axios.post(`${api}/api/v1/generateDataEvolution`, { type, idEvo, idBurnOne }, config);
      const voucher = data.data.data;
      const evolve = await evolution.secondEvolutionWithOne(voucher);
      return Promise.resolve(evolve);
    }
  } catch(e) {
    return Promise.reject(e);
  }
}

const approve = async (id) => {
  try {
    const approve = await contract.approveEscrow(id);
    return Promise.resolve(approve);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getCollectiblesOfOwner = async () => {
  try {
    const collectibles = await contract.getFMonstersOfOwner();
    console.log('collectibles', collectibles);
    return Promise.resolve(collectibles)
  } catch(e) {
    console.log('e', e);
    return Promise.reject(e);
  }
}

const getGraveyard = async () => {
  try {
    const graveyard = await contract.getFmonstersBurned();
    return Promise.resolve(graveyard)
  } catch(e) {
    return Promise.reject(e);
  }
}

const get = async (id) => {
  try {
    const collectible = await contract.get(id);
    return Promise.resolve(collectible);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getTokenURI = async (id) => {
  try {
    const uri = await contract.tokenURI(id);
    return Promise.resolve(uri);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getBurnedURI = async (id, level) => {
  return `${config.development.baseURI}/generation${generation}/level${level}/${id}`;
  /* return `${config.development.baseURI}/level1/${id}`; */
}

String.prototype.hexEncode = function (){
  var hex, i;

  var result = "";
  for (i=0; i<this.length; i++) {
      hex = this.charCodeAt(i).toString(16);
      result += ("000"+hex).slice(-4);
  }

  return result
}

export default {
  mint,
  gift,
  evolve,
  approve,
  getCollectiblesOfOwner,
  getGraveyard,
  get,
  getTokenURI,
  getBurnedURI
}
