import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';

import "./Evolve.css";
import CollectibleService from "../../services/collectible.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    },
    paperText: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderStyle: 'solid',
      borderColor: '#FFF',
      borderWidth: 1,
      marginTop: 5,
      marginLeft: 5,
      marginRight: 10
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 552,
      width: 286
    },
  }),
);

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    backgroundColor: '#FFF'
  };
}

const Evolve = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [evolution, setEvolution] = useState({});
  const [sacrifices, setSacrifices] = useState([]);
  const [sacrifice, setSacrifice] = useState([]);
  const [type, setType] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    try {
      const tokens = await getTokens();
      const evolveToken = tokens.filter((obj) => {
        return parseInt(obj._id) === parseInt(id)
      });
      setEvolution(evolveToken[0]);
      const sacrifices = tokens.filter((obj) => {
        return (parseInt(obj._id) !== parseInt(id) && parseInt(obj.level) <= evolveToken[0].level);
      });
      setSacrifices(sacrifices);
    } catch (e) {
      
    }
  }

  const getTokens = async () => {
    try {
      const data = await CollectibleService.getCollectiblesOfOwner();
      try {
        const items = await Promise.all(data.map(async (i) => {
          if(i.id.toNumber() !== 0) {
            const tokenUri = await CollectibleService.getTokenURI(i.id);
            const meta = await axios.get(tokenUri);
            /* let price = web3.utils.fromWei(i.price.toString(), 'ether'); */
            let item = {
              _id: i.id.toNumber(),
              name: meta.data.name,
              generation: i.generation,
              level: i.level,
              status: i.status,
              element: i.element,
              url: meta.data.image,
              owner: i.owner,
            };
            return item;
          }
        }));
        return Promise.resolve(items)
      } catch(e) {
        return Promise.reject(e);
      }
    } catch(e) {
      return Promise.reject(e);
    }
  }

  const getSacrifice = async () => {
    handleOpen();
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getItem = (token) => {
    if(evolution.level === 2 && token.level === 2) {
      setType(3);
    } else if(evolution.level === 2 && token.level === 1) {
      setType(2);
    } else if(evolution.level === 1 && token.level === 1) {
      setType(1);
    }

    const array = [...sacrifice];
    array.push(token);
    setSacrifice(array);
    handleClose();
  }
  
  const evolve = async() => {
    if(type === 1) {
      if(sacrifice.length === 1) {
        try {
          await CollectibleService.evolve(type, evolution._id, sacrifice[0]._id);
          history.push("/mynfts");
        } catch(e) {
          setErrorMsg(e.message);
          setError(true);

          setTimeout(() => {
            setErrorMsg("");
            setError(false);
          }, 6000);
        }
      } else {
        setErrorMsg("Must be select 1 sacrifice");
        setError(true);

        setTimeout(() => {
          setErrorMsg("");
          setError(false);
        }, 6000);
      }
    } else if(type === 2) {
      if(sacrifice.length === 2) {
        try {
          await CollectibleService.evolve(type, evolution._id, sacrifice[0]._id, sacrifice[1]._id);
          history.push("/mynfts");
        } catch(e) {
          setErrorMsg(e.message);
          setError(true);

          setTimeout(() => {
            setErrorMsg("");
            setError(false);
          }, 6000);
        }
      } else {
        setErrorMsg("Must be select 2 sacrifice");
        setError(true);

        setTimeout(() => {
          setErrorMsg("");
          setError(false);
        }, 6000);
      }
    } else if(type === 3) {
      if(sacrifice.length === 1) {
        try {
          await CollectibleService.evolve(type, evolution._id, sacrifice[0]._id);
          history.push("/mynfts");
        } catch(e) {
          setErrorMsg(e.message);
          setError(true);

          setTimeout(() => {
            setErrorMsg("");
            setError(false);
          }, 6000);
        }
      } else {
        setErrorMsg("Must be select 1 sacrifice");
        setError(true);

        setTimeout(() => {
          setErrorMsg("");
          setError(false);
        }, 6000);
      }
    }
  }

  const listItems = sacrifices.map((token) =>
    <Grid item xs={3} key={ token._id }>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <div onClick={() => {getItem(token)}}>
            <CardMedia
              className={classes.mediaList}
              image={ token.url }
              title={ token.name }
            />
            {/* <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                { token.name }
              </Typography>
            </CardContent> */}
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Select a sacrifice</h2>
      <Grid container spacing={3}>
        {listItems}
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      <h2>Evolve to {evolution.name}</h2>
      <Grid container spacing={3}>
        { evolution && (
          <Grid item xs={3} key={evolution._id}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={evolution.url}
                    title={evolution.name}
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {evolution.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: {evolution.element}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: {evolution.level}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: {evolution.price} FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Evolve
                </Button>
                <Button size="small" color="primary">
                  Publish for Exchange
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        )}
        { sacrifice[0] && (
          <Grid item xs={3} key={sacrifice[0]._id}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={sacrifice[0].url}
                    title={sacrifice[0].name}
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {sacrifice[0].name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: {sacrifice[0].element}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: {sacrifice[0].level}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: {sacrifice[0].price} FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Evolve
                </Button>
                <Button size="small" color="primary">
                  Publish for Exchange
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        )}
        { !sacrifice[0] && (
          <Grid item xs={3}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div onClick={() => {getSacrifice()}} >
                  <CardMedia
                    className={classes.media}
                    image="https://electralink.com/wp-content/uploads/2015/12/leadership-profile-300x300.png"
                    title="Seleccione"
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      S/N
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: S/E
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: S/L
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: S/P FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
        { (type === 2) && sacrifice[1] && (
          <Grid item xs={3} key={sacrifice[1]._id}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={sacrifice[1].url}
                    title={sacrifice[1].name}
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {sacrifice[1].name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: {sacrifice[1].element}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: {sacrifice[1].level}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: {sacrifice[1].price} FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
              {/* <CardActions>
                <Button size="small" color="primary">
                  Evolve
                </Button>
                <Button size="small" color="primary">
                  Publish for Exchange
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        )}
        { (type === 2) && !sacrifice[1] && (
          <Grid item xs={3} >
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div onClick={() => {getSacrifice()}} >
                  <CardMedia
                    className={classes.media}
                    image=""
                    title="Seleccione"
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      S/N
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: S/E
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: S/L
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: S/P FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
      <Button variant="contained" color="primary" onClick={() => { evolve() }}>Evolve</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
}

export default Evolve;
