import React, { useState, useEffect } from "react";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Alert from '@material-ui/lab/Alert';

import CollectibleService from "../../services/collectible.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 552,
      width: 286
    },
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

const TokensList = (props) => {
  const classes = useStyles();
  const tokens = props.collectibles;

  const listItems = tokens.map((token) =>
    <React.Fragment key={token._id}>
      <Grid item xs={3}>
        <Card className={classes.rootCard}>
          <CardActionArea>
            <div>
              <CardMedia
                className={classes.media}
                image={token.url}
                title={token.name}
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {token.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Element: {token.element}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Level: {token.level}
                </Typography>
              </CardContent> */}
            </div>
          </CardActionArea>
        </Card>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const Graveyard = () => {
  const classes = useStyles();
  const [collectibles, setCollectibles] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect( () => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    await getTokens();
  }

  const getTokens = async () => {
    try {
      const data = await CollectibleService.getGraveyard();
      try {
        const items = await Promise.all(data.map(async (i) => {
          if(i.id.toNumber() !== 0) {
            try {
              const tokenUri = await CollectibleService.getBurnedURI(i.id.toNumber(), i.level);
              let meta = await axios(tokenUri);
              meta = meta.data;
              let name = meta.name
              console.log('meta', meta);
              if(name.indexOf("-") > -1) {
                name = name.split("-")[0];
              }
              let item = {
                _id: i.id.toNumber(),
                name: name,
                generation: i.generation,
                level: i.level,
                status: i.status,
                swapStatus: i.swapStatus,
                element: meta.attributes[2].value,
                url: meta.image,
                owner: i.owner,
              };
              return item;
            } catch(e) {
              console.log('e', e);
            }
          }
        }));
        setCollectibles(items);
      } catch(e) {}
    } catch(e) { console.log('e', e); }
  }

  return (
    <React.Fragment>
      <h2>Graveyard</h2>
      { collectibles && collectibles.length > 0 && (
         <div className={classes.root}>
           <Grid container spacing={3}>
              <TokensList collectibles={collectibles} />
           </Grid>
         </div>
      )}
      { collectibles && collectibles.length < 1 && (
        <React.Fragment>
          <h4>No fmonster has been sacrificed yet</h4>
        </React.Fragment>
      )}
      { error && (
        <div className={classes.rootAlert}>
          <Alert severity="error">{ errorMsg }</Alert>
        </div>
      )}
    </React.Fragment>
  );
}

export default Graveyard;
