import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";
import AuthService from "../services/auth.service";

export const login = () => async(dispatch) => {
  try {
    let prov = null;
    /* prov = await AuthService.getProvider(); */
    try {
      const account = (prov) ? prov.account : "";
      const nonce = await AuthService.getNonce(account);
      try {
        const data = await AuthService.login(prov, nonce);
        await dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.account, token: data.token, typeExtension: data.typeExtension },
        });
        /* const u = login.account;
        setUser(u); */
      } catch(error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        await dispatch({
          type: LOGIN_FAIL,
        });

        await dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    } catch(error) {
      console.log('error', error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      await dispatch({
        type: LOGIN_FAIL,
      });

      await dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  } catch(error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    await dispatch({
      type: LOGIN_FAIL,
    });

    await dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
