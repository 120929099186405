import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
/* import CardActions from '@material-ui/core/CardActions'; */
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';

import SwapService from "../../services/swap.service";
import CollectibleService from "../../services/collectible.service";
import UserService from "../../services/user.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    },
    paperText: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      borderStyle: 'solid',
      borderColor: '#FFF',
      borderWidth: 1,
      marginTop: 5,
      marginLeft: 5,
      marginRight: 10
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 552,
      width: 286
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 552,
      width: 286
    },
  }),
);

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    backgroundColor: '#FFF'
  };
}

const Swap = () => {
  const classes = useStyles();
  const [swapOne, setSwapOne] = useState({});
  const [swaps, setSwaps] = useState([]);
  const [swapTwo, setSwapTwo] = useState({});
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const { id } = useParams();

  useEffect(() => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {  
    try {
      const swap = await getSwap(id);
      setSwapOne(swap);
    } catch (e) {

    }

    try {
      const swaps = await getSwaps();
      setSwaps(swaps);
    } catch(e) {

    }
  }

  const getSwaps = async () => {
    try {
      const data = await CollectibleService.getCollectiblesOfOwner();
      try {
        const items = await Promise.all(data.map(async (i) => {
          if(i.id.toNumber() !== 0) {
            const tokenUri = await CollectibleService.getTokenURI(i.id);
            const meta = await axios.get(tokenUri);
            /* let price = web3.utils.fromWei(i.price.toString(), 'ether'); */
            let item = {
              _id: i.id.toNumber(),
              name: meta.data.name,
              generation: i.generation,
              level: i.level,
              status: i.status,
              swapStatus: i.swapStatus,
              element: i.element,
              url: meta.data.image,
              owner: i.owner,
            };
            return item;
          }
        }));
        return Promise.resolve(items);
      } catch(e) {
        return Promise.reject(e);
      }
    } catch(e) {
      return Promise.reject(e);
    }
  }

  const getSwap = async (code) => {
    try {
      const swap = await SwapService.getSwapByCode(code);

      if(swap.length > 0) {
        const tokenUri = await CollectibleService.getTokenURI(swap.item);
        const meta = await axios.get(tokenUri);
        let item = {
          idSwap: swap.id.toNumber(),
          _id: swap.item.toNumber(),
          name: meta.data.name,
          generation: meta.data.attributes[5].value,
          level: meta.data.attributes[2].value,
          status: meta.data.attributes[3].value,
          swapStatus: 2,
          element: meta.data.attributes[1].value,
          url: meta.data.image,
          code: swap.code,
          owner: swap.owner,
        };
        return Promise.resolve(item);
      } else {
        return Promise.reject('error');
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const getSwapTwo = async () => {
    handleOpen();
  }

  const getItem = (token) => {
    setSwapTwo(token);
    handleClose();
  }

  const match = async() => {
    if(Object.keys(swapTwo).length > 0) {
      try {
        await SwapService.publishMatch(swapOne.idSwap, swapTwo._id);
      } catch(e) {

      }
    } else {
      alert("Must be select 1 nft for swap");
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listItems = swaps.map((token) =>
    <Grid item xs={3} key={token._id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <div onClick={() => {getItem(token)}}>
            <CardMedia
              className={classes.mediaList}
              image={token.url}
              title={token.name}
            />
            {/* <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {token.name}
              </Typography>
            </CardContent> */}
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Select a sacrifice</h2>
      <Grid container spacing={3}>
        {listItems}
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      <h2>Swap #{swapOne.code}</h2>
      <Grid container spacing={3}>
        {swapOne && swapOne && (
          <Grid item xs={6} key={swapOne.idSwap}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={swapOne.url}
                    title={swapOne.name}
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {swapOne.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: {swapOne.element}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: {swapOne.level}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: {swapOne.price} FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
        { Object.keys(swapTwo).length < 1 && (
          <Grid item xs={6}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div onClick={() => {getSwapTwo(1)}} >
                  <CardMedia
                    className={classes.media}
                    image=""
                    title="Seleccione"
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      S/N
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: S/E
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: S/L
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: S/P FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
        { Object.keys(swapTwo).length > 0 && (
          <Grid item xs={6}>
            <Card className={classes.rootCard}>
              <CardActionArea>
                <div>
                  <CardMedia
                    className={classes.media}
                    image={swapTwo.url}
                    title={swapTwo.name}
                  />
                  {/* <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {swapTwo.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Element: {swapTwo.element}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Level: {swapTwo.level}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Price: {swapTwo.price} FTM
                    </Typography>
                  </CardContent> */}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
      <Button variant="contained" color="primary" onClick={() => { match() }}>Match</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );
}

export default Swap;
