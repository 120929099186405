import axios from "axios";
import { ethers } from "ethers";
const querystring = require('querystring');

const Fmonsters = require("../artifacts/contracts/Fmonsters.sol/Fmonsters.json");
const FmonstersEscrow = require("../artifacts/contracts/FmonstersEscrow.sol/FmonstersEscrow.json");
const config = require('../config.json');
const contract_mint_address = config.development.contract_mint_address || "";
const contract_escrow_address = config.development.contract_escrow_address || "";
const api = config.development.api_url || "";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const contract = new ethers.Contract(contract_mint_address, Fmonsters.abi, signer);
const escrow = new ethers.Contract(contract_escrow_address, FmonstersEscrow.abi, signer);

const getSwaps = async () => {
  try {
    const swaps = await escrow.getSwapsOfOwner();
    console.log('swaps', swaps);
    return Promise.resolve(swaps)
  } catch(e) {
    return Promise.reject(e);
  }
}

const getMatchs = async () => {
  try {
    const matchs = await escrow.getMatchOfOwner();
    console.log('matchs', matchs);
    return Promise.resolve(matchs)
  } catch(e) {
    return Promise.reject(e);
  }
}

const publishSwap = async (id) => {
  try {
    let token = localStorage.getItem('token');
    token = 'bearer ' + token.split('"').join('');
  
    const config = {
      headers: {
        authorization: token,
      }
    };
  
    const data = await axios.post(`${api}/api/swaps/publishSwap`, querystring.stringify({ id }), config);
    const voucher = data.data.voucher;
  
    const publishSwap = await escrow.publishSwap(voucher);
    return Promise.resolve(publishSwap);
  } catch(e) {
    return Promise.reject(e);
  }
}

const cancelSwap = async (id) => {
  try {
    /* let token = localStorage.getItem('token');
    token = 'bearer ' + token.split('"').join('');
  
    const config = {
      headers: {
        authorization: token,
      }
    };
  
    const data = await axios.post(`${api}/api/swaps/cancelSwap`, querystring.stringify({ id }), config);
    const voucher = data.data.voucher; */
  
    const cancelSwap = await escrow.cancelSwap(id);
    return Promise.resolve(cancelSwap);
  } catch(e) {
    return Promise.reject(e);
  }
}

const publishMatch = async (id, fmonster) => {
  try {
    const publishMatch = await escrow.publishMatch(id, fmonster);
    return Promise.resolve(publishMatch);
  } catch(e) {
    return Promise.reject(e);
  }
}

const cancelMatch = async (id) => {
  try {
    /* let token = localStorage.getItem('token');
    token = 'bearer ' + token.split('"').join('');
  
    const config = {
      headers: {
        authorization: token,
      }
    };
  
    const data = await axios.post(`${api}/api/swaps/cancelMatch`, querystring.stringify({ id }), config);
    const voucher = data.data.voucher; */
  
    const publishMatch = await escrow.cancelMatch(id);
    return Promise.resolve(publishMatch);
  } catch(e) {
    return Promise.reject(e);
  }
}

const confirmSwap = async (idSwap, idMatch) => {
  try {
    const publishMatch = await escrow.confirmSwap(idSwap, idMatch);
    return Promise.resolve(publishMatch);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getSwap = async (id) => {
  try {
    const swap = await escrow.getSwap(id);
    return Promise.resolve(swap);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getSwapByCode = async (code) => {
  try {
    const swap = await escrow.getSwapByCode(code);
    return Promise.resolve(swap);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getMatchOfSwap = async (id) => {
  try {
    const matchs = await escrow.getMatchOfSwap(id);
    return Promise.resolve(matchs);
  } catch(e) {
    return Promise.reject(e);
  }
}

const getMatch = async (id) => {
  try {
    const match = await escrow.getMatch(id);
    return Promise.resolve(match);
  } catch(e) {
    return Promise.reject(e);
  }
}

export default {
  getSwaps,
  getMatchs,
  publishSwap,
  cancelSwap,
  publishMatch,
  cancelMatch,
  confirmSwap,
  getSwap,
  getSwapByCode,
  getMatchOfSwap,
  getMatch
}
