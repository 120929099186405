import axios from "axios";
import { ethers } from "ethers";

import Fmonsters from "../artifacts/contracts/Fmonsters.sol/Fmonsters.json";
const config = require('../config.json');
const contract_address = config.development.contract_address || "";
const api = config.development.api_url || "";

const getSales = async () => {
  const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
  let token = localStorage.getItem('token');
  token = 'bearer ' + token.split('"').join('');

  const config = {
    headers: {
      authorization: token,
    }
  };

  try {
    const response = await axios.get(`${api}/api/v1/sales/` + account, config);
    return Promise.resolve(response.data.sales);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getMySales = async () => {
  const [account] = await window.ethereum.request({ method: "eth_requestAccounts" });
  let token = localStorage.getItem('token');
  token = 'bearer ' + token.split('"').join('');

  const config = {
    headers: {
      authorization: token,
    }
  };

  try {
    const response = await axios.get(`${api}/api/v1/sales/open/` + account, config);
    return Promise.resolve(response.data.sales);
  } catch (e) {
    return Promise.reject(e);
  }
}

const publishSale = async (id, amount) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);
  
  try {
    const sale = await contract.publishSell(id, amount);
    return Promise.resolve(sale);
  } catch (e) {
    return Promise.reject(e);
  }
}

const cancelSale = async (id, idToken) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    const sale = await contract.cancelSell(id, idToken);
    return Promise.resolve(sale);
  } catch (e) {
    return Promise.reject(e);
  }
}

const confirmSale = async (id, idToken, price) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contract_address, Fmonsters.abi, signer);

  try {
    let overrides = {
      value: ethers.utils.parseEther(price.toString())
    };

    const sale = await contract.sell(id, idToken, overrides);
    return Promise.resolve(sale);
  } catch (e) {
    return Promise.reject(e);
  }
}

const getSale = async (id) => {
  let token = localStorage.getItem('token');
  token = 'bearer ' + token.split('"').join('');

  const config = {
    headers: {
      authorization: token,
    }
  };

  try {
    const response = await axios.get(`${api}/api/v1/sale/` + id, config);
    return Promise.resolve(response);
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  getSales,
  getMySales,
  publishSale,
  cancelSale,
  confirmSale,
  getSale
}
