import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

import TrainersService from "../../services/trainers.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    rootCard: {
      maxWidth: 345,
    },
    media: {
      height: 280,
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mediaList: {
      height: 180,
    },
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    backgroundColor: '#FFF'
  };
}

const TokensList = (props) => {
  const classes = useStyles();
  const [modalToken, setModalToken] = useState({});
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [modalStyle] = useState(getModalStyle);
  const tokens = props.collectibles;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listItem = (
    <Grid item xs={12} key={modalToken._id}>
      <Card className={classes.rootCard}>
        <CardActionArea>
          <CardMedia
            className={classes.mediaList}
            image={modalToken.url}
            title={modalToken._id}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {modalToken.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Give a price for sell</h2>
      <Grid container spacing={12} direction="column" alignItems="center" justify="center">
        {listItem}
      </Grid>
      <input type="number" onChange={e => setAmount(e.target.value)} placeholder="Price" />
      <br />
      <Button size="small" color="primary">
        Publish sale
      </Button>
    </div>
  );
  
  const listItems = tokens.map((token) =>
    <React.Fragment key={token._id}>
      <Grid item xs={3}>
        <Card className={classes.rootCard}>
          <CardActionArea>
            <div>
              <CardMedia
                className={classes.media}
                image={token.url}
                title={token.dna}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {token.dna}
                </Typography>
              </CardContent>
            </div>
          </CardActionArea>
          <CardActions>
            
          </CardActions>
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </React.Fragment>
  );

  return (
    <React.Fragment>{listItems}</React.Fragment>
  );
}

const Trainers = () => {
  const classes = useStyles();
  const [collectibles, setCollectibles] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect( () => {
    initialFunctions();
  }, []);

  const initialFunctions = async () => {
    await getTokens();
  }

  const getTokens = async () => {
    try {
      const data = await TrainersService.getCollectiblesOfOwner();
      console.log('data', data);
      try {
        const items = await Promise.all(data.map(async (i) => {
          const tokenUri = await TrainersService.getTokenURI(i.id);
          const meta = await axios.get(tokenUri);
          /* let price = web3.utils.fromWei(i.price.toString(), 'ether'); */
          let item = {
            _id: i.id.toNumber(),
            dna: meta.data.attributes[0].value,
            url: meta.data.image,
            owner: i.owner,
          };
          return item;
        }));
        setCollectibles(items);
      } catch(e) {
        console.log('e', e);
      }
    } catch(e) {
      console.log('e', e);
    }
  }

  return (
    <React.Fragment>
      <h2>My Trainers</h2>
      { collectibles && collectibles.length > 0 && (
         <div className={classes.root}>
           <Grid container spacing={3}>
              <TokensList collectibles={collectibles} />
           </Grid>
         </div>
      )}
      { collectibles && collectibles.length < 1 && (
        <React.Fragment>
          <h4>You don't have tokens</h4>
        </React.Fragment>
      )}
      { error && (
        <div className={classes.rootAlert}>
          <Alert severity="error">{ errorMsg }</Alert>
        </div>
      )}
    </React.Fragment>
  );
}

export default Trainers;
