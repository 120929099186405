import { Subject } from 'rxjs';
import axios from "axios";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
/* import WalletLink from "walletlink"; */
import store from '../store';
const Web3 = require("web3");
const querystring = require('querystring');

const config = require("../config.json");
const subject = new Subject();
const publishSomeData = (data) => subject.next(data);
const getObservable = () => subject;
const api = config.development.api_url || "";

const getProvider = async () => {
  const providerOptions = {
    "custom-metamask": {
      display: {
        logo: "assets/img/logo.svg",
        name: "MetaMask Wallet",
        description: "Connect to your MetaMask Wallet"
      },
      options: {
        appName: 'FMonsters NFT',
        networkUrl: `${config.development.defaultProvider}`,
        chainId: `${config.development.chainId}`,
      },
      package: true,
      connector: async () => {
        const providers = window.ethereum.providers;
        let provider = providers.find(p => p.isMetaMask);

        if(provider) {
          try {
            await provider.request({ method: 'eth_requestAccounts' });
          } catch (error) {
            throw new Error("User Rejected");
          }
        } else {
          provider = window.ethereum;
        }

        console.log("MetaMask provider", provider);
        return provider;
      }
    },
    /* "custom-coinbase": {
      display: {
        logo: 'images/coinbase.svg', 
        name: 'Coinbase',
        description: 'Scan with WalletLink to connect',
      },
      options: {
        appName: 'FMonsters NFT',
        networkUrl: `${config.development.defaultProvider}`,
        chainId: `${config.development.chainId}`,
      },
      package: WalletLink,
      connector: async (_, options) => {
        const walletLink = new WalletLink({
          appName : "Fmonsters"
        });
        const provider = walletLink.makeWeb3Provider(config.development.defaultProvider, config.development.chainId);
        await provider.enable();
        return provider;
      },
    } */
  };
  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    disableInjectedProvider: false,
    providerOptions // required
  });

  try {
    web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    const type = (provider.isMetaMask) ? "metamask" : "coinbase";
    const account = await provider.request({ method: "eth_requestAccounts" });
    localStorage.setItem("typeExtension", type);
    return Promise.resolve({ provider: provider, account: account, type: type});
  } catch(e) {
    console.log('e', e);
    return Promise.reject(e);
  }
}

const getNonce = async (account) => {
  account = await window.ethereum.request({ method: "eth_requestAccounts" });
  
  try {
    const response = await axios.post(`${api}/api/auth/getNonce`, querystring.stringify({ account: account[0].toLowerCase() }));
    const nonce = response.data.nonce;
    return Promise.resolve(nonce);
  } catch(e) {
    return Promise.reject(e);
  }
}

const login = async (prov, nonce) => {
  prov = window.ethereum;

  try {
    const web3 = new Web3(prov);
    const [account] = await prov.request({ method: "eth_requestAccounts" });
    console.log('account', account);
    const message = `I am signing my one-time nonce: ${nonce}`;
    
    if (window.ethereum && window.ethereum.networkVersion === config.development.chainId.toString()) {
      try {
        const signature = await web3.eth.personal.sign(message, account);
        console.log('signature', signature);
        try {
          const response = await axios.post(`${api}/api/auth/signin`, querystring.stringify({ account: account, signature: signature }));
          const token = response.data.token;
          localStorage.setItem("user", JSON.stringify(account));
          localStorage.setItem("token", JSON.stringify(token));
          return Promise.resolve({ account: account, token: token, typeExtension: prov.type });
        } catch(e) {
          console.log('e', e);
          return Promise.reject(e);
        }
      } catch(e) {
        console.log('e', e);
        return Promise.reject(e);
      }
    } else {
      alert('Do you need change to Fantom Network');
    }
  } catch(e) {
    console.log('e', e);
    return Promise.reject(e);
  }
};

const logout = async() => {
  try {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    /* const state = store.getState();
    
    if(state.auth.web3Modal) {
      const web3Modal = state.auth.web3Modal;
      web3Modal.clearCachedProvider(); */

      return Promise.resolve(true);
    /* } else {
      return Promise.reject("Empty web3Modal");
    } */
  } catch(e) {
    console.log('e', e);
    return Promise.reject(false);
  }
};

export default {
  getNonce,
  getProvider,
  login,
  logout,
  publishSomeData,
  getObservable
};