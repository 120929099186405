import React, { useState } from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

import CollectibleService from "../../services/collectible.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    rootAlert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

const Home = () => {
  const classes = useStyles();
  const [address, setAddress] = useState(0);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const gift = async () => {
    try {
      const data = await CollectibleService.gift(address);
    } catch(e) {
      setErrorMsg(e.message);
      setError(true);

      setTimeout(() => {
        setErrorMsg("");
        setError(false);
      }, 6000);
    }
  }

  return (
    <React.Fragment>
      <h2>Home</h2>
      <div style={{ marginTop: 20 }}>
        <span>Do you want gift 1?</span>
        <input type="text" onChange={e => setAddress(e.target.value)} placeholder="Address" />
        <Button variant="contained" color="primary" style={{ marginLeft: 10 }} onClick={ gift }>Gift</Button>
      </div>
      { error && (
        <div className={classes.rootAlert}>
          <Alert severity="error">{ errorMsg }</Alert>
        </div>
      )}
    </React.Fragment>
  );
}

export default Home;